<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <Header
      v-if="
        $route.name != 'SignIn' &&
          $route.name != 'Register' &&
          $route.name != 'ForgottenPassword'
      "
    ></Header>
    <HeaderAuth
      v-if="
        $route.name == 'SignIn' ||
          $route.name == 'Register' ||
          $route.name == 'ForgottenPassword'
      "
    ></HeaderAuth>

    <div class="kotha-default-content flex-fill">
      <router-view :key="$route.fullPath" />
    </div>
    <Footer
      v-if="
        $route.name != 'SignIn' &&
          $route.name != 'Register' &&
          $route.name != 'ForgottenPassword'
      "
    ></Footer>
    <MobileAppMenu />

    <div class="update-dialog" v-if="updateExists && isPWA">
      <div class="update-dialog__content">
        Na voljo je nova različica aplikacije.
      </div>
      <div class="update-dialog__actions">
        <button class="btn btn-success mt-2" @click="refreshApp">
          Posodobi
        </button>
      </div>
    </div>

    <cookie-law :buttonText="'Strinjam se'">
      <div slot="message">
        Spletno mesto uporablja piškotke, s katerimi izboljšujemo uporabniško izkušnjo in zagotavljamo vsebine. <router-link :to="{
                      name: 'TermsAndConditions',
                    hash: '#politika-piskotkov'
                    }">Več</router-link>
      </div>
    </cookie-law>

  </div>
</template>

<script>
import Header from "@/components/Header";
import HeaderAuth from "@/components/HeaderAuth";
import Footer from "@/components/Footer";
import MobileAppMenu from "@/components/MobileAppMenu";
import firebase from "firebase";
import { version } from "../../package.json";
import globalFunctions from "@/helpers/globalFunctions";
import update from '../mixins/update'
import CookieLaw from 'vue-cookie-law'

export default {
  name: "ZapisiSpominaTemplate",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Zapisi spomina",
    // all titles will be injected into this template
    titleTemplate: "%s | Zapisi spomina",
    changed(metaInfo) {
      firebase.analytics().setCurrentScreen(metaInfo.title);
      firebase.analytics().logEvent("page_view");
      firebase.analytics().logEvent("screen_view", {
        app_name: globalFunctions.isPWA() ? "pwa" : "web",
        screen_name: metaInfo.title,
        app_version: version
      });
    }
  },
  components: {
    Header,
    HeaderAuth,
    Footer,
    MobileAppMenu,
    CookieLaw
  },
  computed: {
    isPWA() {
      return globalFunctions.isPWA()
    }
  },
  data() {
    return {
      prompt: false
    };
  },
  mixins: [update],
  mounted() {
    console.log("mounted");
  },

  methods: {
  }
};
</script>
<style lang="scss">
.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 80px;
  transform: translateX(-50%);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 576px;
  min-width: 300px;
  color: white;
  background-color: #473926;
  text-align: left;
  z-index: 99999999999999999;

  &__actions {
    display: flex;
    margin-top: 8px;
  }

  &__button {
    margin-right: 8px;

    &--confirm {
      margin-left: auto;
    }
  }
}
</style>

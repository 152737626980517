<template>
  <header class="kotha-menu marketing-menu">
    <b-navbar toggleable="lg" type="default" class="p-0 fixed-top">
      <b-container>
        <b-navbar-toggle class="w-100" target="nav-collapse">
          <img
              :src="require('@/assets/images/Logo-glava.svg')"
              alt=""
              style="width: 90px; margin-top: 5px; margin-bottom: -5px;"
              class="float-left"
          />

          <div v-if="isCollapseOpen">
            <i
                class="fa fa-times text-white"
                style="float: right; margin-top: 17px; margin-right:13px;"
            ></i>
          </div>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav v-model="isCollapseOpen">
          <b-navbar-nav class="text-uppercase">
            <li class="nav-item d-none d-sm-block">
              <router-link :to="{ name: 'Home' }" active-class="active" exact>
                <img
                    :src="require('@/assets/images/Logo-glava.svg')"
                    alt=""
                    style="width: 90px; padding-top: 5px"
                    class=""
                />
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  :to="{ name: 'About' }"
                  class="nav-link"
                  active-class="active"
                  exact
              >O projektu
              </router-link>
            </li>
            <!--            <li class="nav-item">-->
            <!--              <router-link-->
            <!--                  :to="{ name: 'TermsAnConditions' }"-->
            <!--                  class="nav-link"-->
            <!--                  active-class="active"-->
            <!--                  exact-->
            <!--              >Pogoji uporabe-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li class="nav-item">
              <router-link
                  :to="{ name: 'Contact' }"
                  class="nav-link"
                  active-class="active"
                  exact
              >NAVODILA
              </router-link>
            </li>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-dropdown ref="dropdownLang" id="languagePickerDropdown" variant="link" class="text-white m-md-2">
              <template #button-content>
                {{ selectedLanguage }}
              </template>
              <b-dropdown-item :active="selectedLanguage === 'SI'" @click="setSelectedLanguage('SI')">slovensko
              </b-dropdown-item>
              <b-dropdown-item :active="selectedLanguage === 'EN'" @click="setSelectedLanguage('EN')">english
              </b-dropdown-item>
            </b-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </header>
</template>

<script>
// @ is an alias to /src
// import {db} from "../../main";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      isCollapseOpen: false,
      selectedLanguage: null,
    };
  },
  computed: {},
  mounted() {
    this.getSelectedLanguage();
  },
  methods: {
    closeDropdown() {
      console.log("HERE!!!!");
      this.$refs.dropdown.hide(true);
    },
    async getSelectedLanguage() {
      if (localStorage.getItem('zapisi_spomina_language')) {
        this.selectedLanguage = localStorage.getItem('zapisi_spomina_language')
      } else {
        this.selectedLanguage = await this.$globalFunctions.detectUserLocation();
        this.setSelectedLanguage(this.selectedLanguage);
      }
    },
    setSelectedLanguage(lang) {
      this.selectedLanguage = lang;
      localStorage.setItem('zapisi_spomina_language', lang);
      console.log("this.router", this.$route);
      if (lang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if (lang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  }
};
</script>

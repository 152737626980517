<template>
  <header class="kotha-menu marketing-menu">
    <b-navbar toggleable="lg" type="default" class="p-0 pb-lg-0 fixed-top">
      <b-container>
        <b-navbar-toggle class="w-100" target="nav-collapse">
          <img
            :src="require('@/assets/images/Logo-glava.svg')"
            alt=""
            style="width: 90px; margin-top: 5px; margin-bottom: -5px;"
            class="float-left"
          />

          <div v-if="!isCollapseOpen">
            <b-img
              v-if="getUser && getUser.profileImg"
              :src="getUser && getUser.profileImg"
              rounded="circle"
              width="40"
              height="40"
              style="float: right; margin-top: 7px; border: 2px solid #fff; object-fit: cover;"
            />
            <b-img
              v-if="getUser && !getUser.profileImg"
              :src="require('@/assets/images/user-krogec-white.svg')"
              rounded="circle"
              width="40"
              height="40"
              style="float: right; margin-top: 4px; object-fit: cover;"
            />

            <router-link
              v-if="!getUser"
              :to="{ name: 'SignIn' }"
              class="nav-link signInBtn d-inline-block"
              style="float: right; margin-top: 8px;"
              active-class="active"
              exact
              >Prijava
            </router-link>
          </div>
          <div v-if="isCollapseOpen">
            <i
              class="fa fa-times text-white"
              style="float: right; margin-top: 17px; margin-right:13px;"
            ></i>
          </div>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav v-model="isCollapseOpen" class="pb-md-4 pb-lg-0">
          <b-navbar-nav class="text-uppercase">
            <li class="nav-item d-none d-lg-block">
              <router-link :to="{ name: 'Home' }" active-class="active" exact>
                <img
                  :src="require('@/assets/images/Logo-glava.svg')"
                  alt=""
                  style="width: 90px; padding-top: 5px"
                  class=""
                />
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'About' }"
                class="nav-link"
                active-class="active"
                exact
                >O projektu
              </router-link>
            </li>
            <!--            <li class="nav-item">-->
            <!--              <router-link-->
            <!--                  :to="{ name: 'TermsAnConditions' }"-->
            <!--                  class="nav-link"-->
            <!--                  active-class="active"-->
            <!--                  exact-->
            <!--              >Pogoji uporabe-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li class="nav-item">
              <router-link
                :to="{ name: 'Instructions' }"
                class="nav-link"
                active-class="active"
                exact
                >O APLIKACIJI
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                  :to="{ name: 'Vsa' }"
                  class="nav-link"
                  active-class="active"
                  exact
              >VSA
              </router-link>
            </li>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto" v-if="!getUser">
            <li class="nav-item">
              <router-link
                :to="{ name: 'SignIn' }"
                class="nav-link signInBtn"
                active-class="active"
                exact
                >Prijava
              </router-link>
            </li>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-navbar-nav class="text-uppercase" v-if="getUser">
              <li class="nav-item" v-if="getUser && getUser.admin">
                <router-link
                  :to="{ name: 'Dashboard' }"
                  class="text-info nav-link"
                  style="padding-top: 18px;"
                  active-class="active"
                  exact
                  >Administratorski vmesnik
                </router-link>
              </li>
            </b-navbar-nav>
            <b-dropdown ref="dropdown" variant="link" class="text-white m-md-2" v-if="getUser">
              <template #button-content>
                <b-img
                  v-if="getUser.profileImg"
                  :src="getUser.profileImg"
                  rounded="circle"
                  width="30"
                  height="30"
                  style="object-fit: cover; margin-top: -5px;"
                  class="mr-1"
                />

                <b-img
                  v-if="!getUser.profileImg"
                  :src="require('@/assets/images/user-krogec-white.svg')"
                  rounded="circle"
                  width="30"
                  height="30"
                  style="object-fit: cover;  margin-top: -5px;"
                  class="mr-1"
                />

                <span class="pl-1">{{ getUser.displayName }}</span>
              </template>
              <li role="presentation" @click="closeDropdown()">
                <router-link
                  :to="{ name: 'EditPost' }"
                  class="dropdown-item"
                  active-class="active"
                  exact
                  >Nov zapis
                </router-link>
              </li>
              <li role="presentation" @click="closeDropdown()">
                <router-link
                  :to="{ name: 'MyPosts' }"
                  class="dropdown-item"
                  active-class="active"
                  exact
                  >Moji zapisi
                </router-link>
              </li>
              <li role="presentation" @click="closeDropdown()">
                <router-link
                  :to="{ name: 'MyProfile' }"
                  class="dropdown-item"
                  active-class="active"
                  exact
                  >Moj profil
                </router-link>
              </li>

              <b-dropdown-item @click="signOut">Odjava</b-dropdown-item>
            </b-dropdown>
            <b-dropdown ref="dropdownLang" id="languagePickerDropdown" variant="link" class="text-white m-md-2">
              <template #button-content>
                {{selectedLanguage}}
              </template>
              <b-dropdown-item :active="selectedLanguage === 'SI'" @click="setSelectedLanguage('SI')">slovensko</b-dropdown-item>
              <b-dropdown-item :active="selectedLanguage === 'EN'" @click="setSelectedLanguage('EN')">english</b-dropdown-item>
            </b-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <AdminNav
      v-if="getUser && getUser.admin && $route.fullPath.includes('/admin')"
    />
  </header>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase";
import AdminNav from "@/components/AdnimNav";
// import {db} from "../../main";

export default {
  name: "Footer",
  components: {
    AdminNav
  },
  data() {
    return {
      isCollapseOpen: false,
      selectedLanguage: null,
    };
  },
  computed: {
    getUser() {
      console.log("getUser", this.$store.getters.getUser);
      return this.$store.getters.getUser;
    }
  },
  mounted() {
    this.getSelectedLanguage();
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.dispatch("setUser", null);
          this.$router.push({
            name: "SignIn"
          });
        });
    },
    closeDropdown() {
      console.log("HERE!!!!");
      this.$refs.dropdown.hide(true);
    },
    async getSelectedLanguage() {
      if(localStorage.getItem('zapisi_spomina_language')) {
        this.selectedLanguage = localStorage.getItem('zapisi_spomina_language')
      } else {
        this.selectedLanguage = await this.$globalFunctions.detectUserLocation();
        this.setSelectedLanguage(this.selectedLanguage);
      }
    },
    setSelectedLanguage(lang) {
      this.selectedLanguage = lang;
      localStorage.setItem('zapisi_spomina_language', lang);
      console.log("this.router", this.$route);
      if(lang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(lang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }

  }
};
</script>

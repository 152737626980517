<template>
  <div class="mobileMenu">
    <div class="mobileMenuActionsContainer d-flex justify-content-between">
      <router-link
        v-if="$route.name !== 'Home'"
        :to="{ name: 'Home' }"
        active-class="active"
        exact
        class="menuBtn"
      >
        <b-img :src="require('@/assets/images/home.svg')" width="30" />
      </router-link>

      <div v-if="$route.name === 'Home'" class="menuBtn" @click="scrollToTop">
        <b-img :src="require('@/assets/images/home.svg')" width="30" />
      </div>

      <a class="menuBtn" @click="showModal('searchModal')">
        <b-img :src="require('@/assets/images/search.svg')" width="30" />
      </a>

      <router-link
        :to="{ name: getUser ? 'EditPost' : 'SignIn' }"
        active-class="active"
        exact
        class="menuBtn"
      >
        <b-img :src="require('@/assets/images/plus-square.svg')" width="30" />
      </router-link>

      <a class="menuBtn" @click="showModal('categoriesModal')">
        <b-img :src="require('@/assets/images/list.svg')" width="30" />
      </a>

      <a class="menuBtn" @click="showModal('aboutModal')">
        <b-img :src="require('@/assets/images/info.svg')" width="30" />
      </a>
    </div>

    <b-modal
      id="searchModal"
      ref="searchModal"
      hide-footer
      title="Išči po zapisih"
    >
      <div class="pb-5" style="height: 100%;">
        <Search />
      </div>
    </b-modal>

    <b-modal
      id="categoriesModal"
      ref="categoriesModal"
      hide-footer
      title="Zvrsti in podzvrsti"
    >
      <div class="pb-5" style="height: 100%;">
        <Categories />
      </div>
    </b-modal>

    <b-modal id="aboutModal" ref="aboutModal" hide-footer title="Info">
      <div class="pb-5" style="height: 100%;">
        <div class="kotha-sidebar override d-block">
          <aside class="widget about-me-widget text-center">
            <PromoSlider />

            <a class="btn btn-success mt-3 d-block" style="border-color: #69a637; background-color: #69a637; border-radius: 20px;" @click="donate">Podprite nas s PayPal donacijo</a>
          </aside>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Search from "@/components/Search";
import Categories from "@/components/Categories";
import PromoSlider from "@/components/PromoSlider";

export default {
  name: "MobileAppMenu",
  components: {
    Categories,
    Search,
    PromoSlider
  },
  props: {},
  data() {
    return {
      user: {}
    };
  },
  computed: {
    getUser() {
      console.log("getUser", this.$store.getters.getUser);
      return this.$store.getters.getUser;
    }
  },
  mounted() {

  },
  methods: {
    showModal(modal) {
      this.$refs["searchModal"].hide();
      this.$refs["categoriesModal"].hide();
      this.$refs["aboutModal"].hide();
      this.$refs[modal].show();
    },
    hideModals() {
      this.$refs["searchModal"].hide();
      this.$refs["categoriesModal"].hide();
      this.$refs["aboutModal"].hide();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    donate() {
      document.getElementById('donate-button').click();
    },
  }
};
</script>
